import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faEdit,
  faSave,
  faSignOut,
  faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonContainer } from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { translate } from 'utils';
import { Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useModal, useStores } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { QuizService } from 'services';
import { observer } from 'mobx-react-lite';
import { ROUTES } from 'utils/constants';
import { findSmallestCategories } from 'components/_commons/Quiz/QuizHelper';

export const QuizEditionNav = observer(({
  isEditionMode, viewMode, setViewMode, setIsTempEditionMode
}) => {
  const { quizStore, editionStore } = useStores();
  const { editionMode, editionPreviewMode } = editionStore;
  const { quiz } = quizStore;

  const { quizId } = useParams();
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (isEditionMode) {
      editionStore.setEditionMode();
      setIsTempEditionMode(false);
    }
  }, [isEditionMode, viewMode, setIsTempEditionMode, editionStore]);

  const toggleEdition = useCallback(() => {
    editionStore.toggleEdition();
    editionStore.setFormType('quiz');
  }, [editionStore]);

  const showWarningModal = useCallback((action, title, text) => displayModal({
    type: 'WARNING',
    title,
    text,
    buttonCancel: translate('button.cancel'),
    buttonConfirm: translate('button.iAmSure'),
    onConfirm: action
  }), [displayModal]);

  const handleQuitEditionMode = useCallback(() => {
    showWarningModal(() => {
      setViewMode('quiz');
      editionStore.reset();
      history.push({
        pathname: ROUTES.ADMIN_QUIZ,
        state: {}
      });
    }, translate('quiz.titleWarningLeaveEdition'), translate('quiz.warningLeaveEdition'));
  }, [editionStore, showWarningModal, setViewMode, history]);

  const handleQuitPreviewMode = useCallback(() => {
    editionStore.reset();
    history.push({
      pathname: ROUTES.ADMIN_QUIZ,
      state: {}
    });
  }, [editionStore, history]);

  const handleChangeMode = useCallback((_, mode) => {
    if (!mode) return;
    setViewMode(mode);
    editionStore.setFormType(mode);
  }, [editionStore, setViewMode]);

  const updateQuiz = useCallback(() => {
    setIsSaving(true);
    QuizService.updateQuiz(quizId, quiz)
      .then(() => {
        setViewMode('quiz');
        editionStore.reset();
        history.push({
          pathname: ROUTES.ADMIN_QUIZ,
          state: {}
        });
        enqueueSnackbar(translate('quiz.quizCorrectlyUpdated'), { variant: 'success' });
      })
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }));
  }, [quizId, quiz, enqueueSnackbar, editionStore, history, setViewMode]);

  const handleSubmit = useCallback(() => {
    let smallestCategories = [];

    quizStore.toggleSubmitQuizForm(true);
    quizStore.isQuizFormValid(quiz);

    quiz.pages.forEach(page => {
      if (page.isRandom && smallestCategories.length === 0) {
        const { smallestCat } = findSmallestCategories(page);

        smallestCategories = smallestCat;

        return smallestCategories;
      }
      return null;
    });

    const { isValid } = quizStore;

    if (isValid.status && smallestCategories.length === 0) {
      if (quizId) {
        if (quiz.isOld) {
          return displayModal({
            type: 'WARNING',
            title: translate('warnings.warning'),
            text: translate('warnings.quiz.editingOldQuiz'),
            buttonCancel: translate('button.cancel'),
            buttonConfirm: translate('button.confirm'),
            onConfirm: () => updateQuiz()
          });
        }
        return updateQuiz();
      }
      setIsSaving(true);
      return QuizService.createQuiz(quiz)
        .then(response => {
          quizStore.loadQuiz(response);
          history.replace({ pathname: `${ROUTES.ADMIN_QUIZ_CREATION}/${response}` });
          enqueueSnackbar(translate('quiz.quizCorrectlyCreated'), { variant: 'success' });
        })
        .catch(error => enqueueSnackbar(error.message || error, { variant: 'error' }))
        .finally(() => setIsSaving(false));
    }

    if (isValid.error === 'title') {
      enqueueSnackbar(translate('quiz.missingTitle'), { variant: 'error' });
      return handleChangeMode(null, 'quiz');
    }
    if (isValid.error === 'code') {
      enqueueSnackbar(translate('quiz.missingCode'), { variant: 'error' });
      return handleChangeMode(null, 'quiz');
    }
    if (isValid.error === 'repeatLimit') {
      enqueueSnackbar(translate('quiz.repeatLimitMinValue'), { variant: 'error' });
      return handleChangeMode(null, 'quiz');
    }
    if (isValid.error === 'noPages') {
      return enqueueSnackbar(translate('quiz.addOnePageMin'), { variant: 'error' });
    }
    if (isValid.error === 'noQuestions') {
      return enqueueSnackbar(translate('quiz.addOneQuestionMin'), { variant: 'error' });
    }
    if (isValid.error === 'noCategories') {
      return enqueueSnackbar(translate('quiz.addOneCategoryMin'), { variant: 'error' });
    }
    if (smallestCategories.length > 0) {
      return enqueueSnackbar(translate('quiz.pagesWithTooFewQuestions'), { variant: 'error' });
    }

    return null;
  }, [quiz, quizId, quizStore, history, enqueueSnackbar, handleChangeMode, displayModal, updateQuiz]);

  const HeaderPreviewMode = () => (
    <Grid alignItems="center" container justifyContent="space-between">
      <Grid item>
        <ButtonContainer>
          <Button
            startIcon={<FontAwesomeIcon icon={faSignOut} />}
            style={{ marginRight: 10 }}
            type="danger"
            variant="text"
            onClick={handleQuitPreviewMode}
          >
            {translate('quiz.quizMode')}
          </Button>
        </ButtonContainer>
      </Grid>
      <Grid item>
        <ButtonContainer>
          <Button
            startIcon={<FontAwesomeIcon icon={faEdit} />}
            type="secondary"
            onClick={toggleEdition}
          >
            {translate('button.edit')}
          </Button>
        </ButtonContainer>
      </Grid>
    </Grid>
  );

  const HeaderEditionMode = () => (
    <Grid alignItems="center" container justifyContent="space-between">
      <Grid item>
        <ToggleButtonGroup
          exclusive
          value={viewMode}
          onChange={handleChangeMode}
        >
          <ToggleButton value="quiz">
            {translate('quiz.quizEditor')}
          </ToggleButton>
          <ToggleButton value="quizPages">
            {translate('quiz.quizPageEditor')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <ButtonContainer>
          <Button
            startIcon={<FontAwesomeIcon icon={faSignOut} />}
            type="danger"
            variant="text"
            onClick={handleQuitEditionMode}
          >
            {translate('quiz.cancelAndQuit')}
          </Button>
          <Button
            disabled={isSaving}
            startIcon={<FontAwesomeIcon icon={isSaving ? faSpinner : faSave} spin={isSaving} />}
            onClick={handleSubmit}
          >
            {translate('quiz.saveAndQuit')}
          </Button>
        </ButtonContainer>
      </Grid>
    </Grid>
  );

  return (
    <HeaderEditionNav fullWidth={editionMode || editionPreviewMode}>
      {editionMode || editionPreviewMode ? <HeaderEditionMode /> : <HeaderPreviewMode />}
    </HeaderEditionNav>
  );
});

QuizEditionNav.propTypes = {
  viewMode: PropTypes.string.isRequired,
  setViewMode: PropTypes.func.isRequired,
  isEditionMode: PropTypes.bool.isRequired,
  setIsTempEditionMode: PropTypes.func.isRequired
};
