import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { faUserEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Grid } from '@material-ui/core';

import { translate } from 'utils';

import { FormElement, SelectField } from 'components';

import { CustomActionButtons } from 'components/Modals/_CustomActionButtons';
import { groupBy } from 'lodash/collection';
import PropTypes from 'prop-types';

import {
  isEmpty, isEqual, unionWith, uniqWith
} from 'lodash';

import { StructureLevelOption, StructureLevelValue } from 'components/_commons/Form/ItemOption/StructureLevelOption';

import { components } from 'react-select';
import { HeaderModal } from './_HeaderModal';

const UpdateUserForm = styled.form`
  .MuiToggleButton-root,
  .MuiToggleButton-root:hover {
    background-color: var(--white);
  }

  .MuiToggleButton-root.Mui-selected:first-child,
  .MuiToggleButton-root.Mui-selected:first-child:hover {
    background-color: var(--success-color);
  }

  .MuiToggleButton-root.Mui-selected:last-child,
  .MuiToggleButton-root.Mui-selected:last-child:hover {
    background-color: var(--error-color);
  }

  @media(min-width: 768px) {
    width: 900px;
  }
`;

export const MultiValueRemove = (removeProps) => {
  const clearableValue = removeProps?.data?.clearableValue;
  return clearableValue && (
    <components.MultiValueRemove {...removeProps} />
  );
};

const ActivityRoleSelector = ({
  handleChangeLevel,
  defaultLevel,
  handleChangeLevelRoles,
  defaultLevelRoles,
  accessibleLevels,
  accessibleRoles,
  selectedLevels,
  isClearable,
  isLevelDisabled
}) => {
  const [level, setLevel] = useState(defaultLevel);
  const [levelRoles, setLevelRoles] = useState(defaultLevelRoles?.map(
      (value) => ({
        ...value,
        clearableValue: accessibleRoles ? accessibleRoles.map((role) => role.value).includes(value.value) : false
      })
  ));

  const updateLevelRoles = roles => {
    const newRoles = roles
        ? roles.map((role) => (({
          ...role,
          clearableValue: role.clearableValue ?? accessibleRoles?.map((r) => r.value).includes(role.value),
          structureLevel: level
        })))
        : [];
    setLevelRoles(newRoles);
    handleChangeLevelRoles(newRoles);
  };

  useEffect(() => {
    if (!isEmpty(levelRoles)) {
      updateLevelRoles(levelRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  return (
    <>
      <SelectField
        components={{ Option: StructureLevelOption, SingleValue: StructureLevelValue }}
        isClearable={!isEmpty(level) && isClearable}
        isDisabled={
            level.value
            && (
                !accessibleLevels.find((al) => al.value === level.value) || isLevelDisabled
            )
        }
        label="modalUserUpdate.selectStructureLevel"
        name="structure-level-selector"
        options={accessibleLevels.map((option) => ({
          ...option,
          isDisabled: selectedLevels.some((selectedLevel) => selectedLevel.value === option.value)
        }))}
        required={false}
        value={level}
        onChange={value => {
          setLevel(value);
          handleChangeLevel(value);
        }}
      />

      {!isEmpty(level)
        && (
          <div style={{ marginLeft: 20, marginBottom: 20 }}>
            <SelectField
              closeMenuOnSelect={accessibleRoles?.filter(option => !levelRoles
                .map(lr => lr.value)
                .includes(option.value))?.length === 1}
              components={{ MultiValueRemove }}
              isClearable={isClearable}
              isMulti
              isOptionDisabled={(option) => !accessibleRoles.includes(option)}
              label="modalUserUpdate.rolesForStructureLevel"
              name="activity-roles"
              options={accessibleRoles}
              value={levelRoles}
              onChange={updateLevelRoles}
            />
          </div>
        )}
    </>
  );
};

export const UpdateRolesModal = ({
  onClose, onConfirm, closeOnSubmit, defaultValues, app, administrableRolesByStructureLevel
}) => {
  const {
    mapRoles
  } = defaultValues;

  const getAvailableStructureLevels = useCallback(
      () => Array.from(administrableRolesByStructureLevel ? administrableRolesByStructureLevel.keys() : []),
      [administrableRolesByStructureLevel]
  );

  const getAvailableStructureLevel = useCallback(
      (structureLevel) => getAvailableStructureLevels()
          .find(
            (structureLevelOption) => structureLevelOption?.value === (structureLevel?.value ? structureLevel.value : null) // The structure level with "null" as value is the key to global roles in map
          ),
      [getAvailableStructureLevels]
  );

  const getAvailableRolesByLevel = useCallback(
      (structureLevel) => administrableRolesByStructureLevel?.get(getAvailableStructureLevel(structureLevel)),
      [administrableRolesByStructureLevel, getAvailableStructureLevel]
  );

  const getAvailableStructureLevelsForActivity = useCallback(
    () => getAvailableStructureLevels().filter(
      (structureLevelOption) => !!structureLevelOption?.value // The structure level with "null" as value is the key to global roles in map
    ),
    [getAvailableStructureLevels]
  );

  const isAccessibleRole = useCallback(
      (role, structureLevel) => getAvailableRolesByLevel(structureLevel)?.map((selectItem) => selectItem?.value)
          .includes(role?.value),
      [getAvailableRolesByLevel]
  );

  const isAtLeastOneImmutableRole = useCallback(
      (roles, structureLevel) => roles.some((role) => !isAccessibleRole(role, structureLevel)),
      [isAccessibleRole]
  );

  const isAtLeastOneAvailableActivityRole = useCallback(
    () => {
      const structureLevelsWithActivity = getAvailableStructureLevelsForActivity();
      let activityRoleCount = 0;
      for (let i = 0; i < structureLevelsWithActivity.length; i++) {
        const roles = getAvailableRolesByLevel(structureLevelsWithActivity[i]);
        if (roles) {
          activityRoleCount += roles.length;
        }
      }
      return activityRoleCount > 0;
    },
  [getAvailableRolesByLevel, getAvailableStructureLevelsForActivity]
);

  const compareRoles = ((role1, role2) => {
    if (role1.clearableValue && !role2.clearableValue) {
      return 1;
    }
    if (!role1.clearableValue && role2.clearableValue) {
      return -1;
    }
    return 1;
  });

  const rolesByNeedStructureLevel = groupBy(mapRoles[app] ?? [], 'needStructureLevel');
  const [activityRoles, setActivityRoles] = useState(rolesByNeedStructureLevel.true ? rolesByNeedStructureLevel.true.map(
      (value) => {
        const accessibleRoles = getAvailableRolesByLevel(value?.structureLevel);
        return ({
          ...value,
          clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
        });
      }
  ) : []);

  const [globalRoles, setGlobalRoles] = useState(rolesByNeedStructureLevel.false ? rolesByNeedStructureLevel.false.map(
      (value) => {
        const accessibleRoles = getAvailableRolesByLevel(null); // The structure level with "null" as value is the key to global roles in map
        return ({
          ...value,
          clearableValue: accessibleRoles ? accessibleRoles.map((role) => role.value).includes(value.value) : false
        });
      }
  ) : []);

  const [structureLevels, setStructureLevels] = useState([...uniqWith(activityRoles.map(r => r.structureLevel).filter(sl => !!sl), isEqual)]);

  const getActivityRolesByLevel = useCallback(
    (structureLevel) => activityRoles
      .filter((r) => r.structureLevel?.value === structureLevel?.value),
    [activityRoles]
  );

  useEffect(() => {
    const lastStructureLevel = structureLevels.slice(-1)[0];
    if (structureLevels.length < getAvailableStructureLevels().length) {
      if (!lastStructureLevel || Object.keys(lastStructureLevel).length !== 0) {
        setStructureLevels([...structureLevels, {}]);
      }
    }
  }, [structureLevels, getAvailableStructureLevels]);

  const updateStructureLevelRoles = (level, index) => {
    const previousStructureLevel = structureLevels[index];
    if (previousStructureLevel) {
      setActivityRoles(activityRoles.filter((r) => r.structureLevel?.value !== previousStructureLevel?.value));
    }
    if (level != null) {
      setStructureLevels([...structureLevels.slice(0, index), level, ...structureLevels.slice(index + 1)]);
    } else {
      setStructureLevels([...structureLevels.slice(0, index), ...structureLevels.slice(index + 1)]);
    }
  };

  const handleChangeLevelRoles = (newRoles, structureLevel) => {
    const activityRolesNotForCurrentLevel = activityRoles
      .filter((r) => r.structureLevel?.value !== structureLevel?.value)
      .map(
        (value) => {
          const accessibleRoles = getAvailableRolesByLevel(structureLevel);
          return ({
            ...value,
            clearableValue: accessibleRoles ? accessibleRoles.map((role) => role.value).includes(value.value) : false
          });
        }
      );
    setActivityRoles(unionWith(activityRolesNotForCurrentLevel, newRoles, isEqual));
  };

  const handleValidateModal = e => {
    e.preventDefault();
    onConfirm(unionWith(globalRoles, activityRoles, isEqual));
    if (closeOnSubmit) {
      onClose();
    }
  };

  return (
    <UpdateUserForm onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <Grid alignItems="center" container justifyContent="space-between">
          <div>
            <FontAwesomeIcon icon={faUserEdit} />
            {`${translate('modalUserUpdate.editUser')} : ${defaultValues.person.firstName} ${defaultValues.person.lastName}`}
          </div>
        </Grid>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="column">
          <FormElement label={translate('modalUserUpdate.editGlobalRoles')}>
            <SelectField
              closeMenuOnSelect={false}
              components={{ MultiValueRemove }}
              isClearable={!isAtLeastOneImmutableRole(globalRoles, null)} // The structure level with "null" as value is the key to global roles in map
              isClearableCustomComponent
              isMulti
              isOptionDisabled={(option) => !isAccessibleRole(option, null)} // The structure level with "null" as value is the key to global roles in map
              label="common.roles"
              name="roles"
              options={getAvailableRolesByLevel(null)} // The structure level with "null" as value is the key to global roles in map
              value={globalRoles.sort((role1, role2) => compareRoles(role1, role2))}
              onChange={
                (values) => {
                  setGlobalRoles(
                    values?.map((role) => {
                      const accessibleRoles = getAvailableRolesByLevel(null); // The structure level with "null" as value is the key to global roles in map
                      return (role.clearableValue
                          ? role
                          : ({
                            ...role,
                            clearableValue: accessibleRoles?.map((r) => r.value).includes(role.value)
                          })
                      );
                    })
                  );
                }
              }
            />
            {
              (isAtLeastOneAvailableActivityRole() || !isEmpty(activityRoles)) && (
                <FormElement label={translate('modalUserUpdate.editActivityRoles')}>
                  {structureLevels.map((structureLevel, index) => (
                    <ActivityRoleSelector
                      accessibleLevels={getAvailableStructureLevelsForActivity()}
                      accessibleRoles={getAvailableRolesByLevel(structureLevel)}
                      defaultLevel={structureLevel}
                      defaultLevelRoles={activityRoles.filter(r => r.structureLevel?.value === structureLevel?.value)}
                      handleChangeLevel={newLevel => updateStructureLevelRoles(newLevel, index)}
                      handleChangeLevelRoles={newRoles => handleChangeLevelRoles(newRoles, structureLevel)}
                      isClearable={!isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel)}
                      isLevelDisabled={isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel)}
                      key={structureLevel?.value ?? index}
                      selectedLevels={structureLevels}
                    />
                  ))}
                </FormElement>
              )
            }
          </FormElement>
        </Grid>
      </DialogContent>

      <CustomActionButtons
        onClose={onClose}
      />
    </UpdateUserForm>
  );
};

UpdateRolesModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

UpdateRolesModal.defaultProps = {
  closeOnSubmit: true
};
