import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@material-ui/core';
import { FormAddress, FormCountryIdentifier } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { translate, UserHelper } from 'utils';

import { CompanyService, CountryService } from 'services';
import { CustomActionButtons } from './_CustomActionButtons';
import { HeaderModal } from './_HeaderModal';

export const CreateInstitutionModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, parentInstitutionGroupId, parentCompanyId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [identifierFields, setIdentifierFields] = useState([]);
  const [formState, {
    text,
    raw
  }] = useFormState({
    ...defaultValues,
    activated: defaultValues.activated !== null ? defaultValues.activated : true,
    attestationNumber: defaultValues.attestationNumber || '',
    address1: (defaultValues.address && defaultValues.address.address1) || '',
    address2: (defaultValues.address && defaultValues.address.address2) || '',
    postalCode: (defaultValues.address && defaultValues.address.postalCode) || '',
    city: (defaultValues.address && defaultValues.address.city) || '',
    automaticCerfaDelivery: (defaultValues.automaticCerfaDelivery) || false,
    institutionName: defaultValues.name || ''
  });

  useEffect(() => {
    if (defaultValues.id) {
      setIsPublic(defaultValues.isPublic);
    } else {
      CompanyService.isPublic(parentCompanyId)
        .then(setIsPublic);
    }
    // eslint-disable-next-line
  }, [parentCompanyId, defaultValues]);

  useEffect(() => {
    const defaultCountry = UserHelper.getDefaultCountry(defaultValues.address);
    CountryService.getCountrySelectItems()
      .then(countries => {
        formState.setField('country', countries.find(country => country.value.trim() === defaultCountry.value.trim()));
      });
    // eslint-disable-next-line
  }, [defaultValues.address]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    setIsLoading(true);
    const isFormValid = document.forms.institutionForm && document.forms.institutionForm.reportValidity();

    if (isFormValid) {
      const newInstitution = {
        ...formState.values,
        identifiers: identifierFields,
        id: defaultValues.id || null,
        name: formState.values.institutionName || '',
        address: {
          hashId: (defaultValues.address && defaultValues.address.hashId) || null,
          address1: formState.values.address1,
          address2: formState.values.address2,
          postalCode: formState.values.postalCode,
          city: formState.values.city,
          country: {
            ...formState.values.country,
            key: formState.values.country && formState.values.country.countryCode
          }
        },
        parentInstitutionGroupId,
        parentCompanyId
      };

      onConfirm(newInstitution).finally(() => setIsLoading(false));

      if (closeOnSubmit) {
        onClose();
      }
    }
  }, [formState, closeOnSubmit, onClose, onConfirm, defaultValues, parentInstitutionGroupId, parentCompanyId, identifierFields]);

  return (
    <form autoComplete="off" name="institutionForm" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        <Typography component="span">
          {defaultValues && defaultValues.name
            ? translate('modalInstitution.editInstitution')
            : translate('modalInstitution.newInstitution')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ minWidth: '650px' }}>
        <Grid container direction="column">
          <FormCountryIdentifier
            columnSize={12}
            formState={formState}
            hasDefaultSiret={defaultValues.siret}
            identifierFields={identifierFields}
            isPublic={isPublic}
            raw={raw}
            setIdentifierFields={setIdentifierFields}
            text={text}
          />

          <TextField
            autoComplete="new-password"
            inputProps={{ maxLength: 32 }}
            label={translate('common.attestationNumber')}
            name="attestationNumber"
            {...text('attestationNumber')}
          />

          <div style={{ margin: '1rem 0' }} />

          <FormAddress
            formState={formState}
            isRequired
            text={text}
            withCountry={false}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={isLoading}
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
};

CreateInstitutionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

CreateInstitutionModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};
