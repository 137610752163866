import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faEdit, faSave } from '@fortawesome/pro-regular-svg-icons';
import {
  faBalanceScale,
  faList
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Tab, Tabs, Typography
} from '@material-ui/core';
import {
  Button, CountryRules, Flag, LegalStatusList, Wrapper
} from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { IconButton } from 'components/_commons';
import { ActivationSwitch } from 'components/_commons/Switch';
import { useModal, useStores } from 'hooks';
import { CountryView } from 'models/CountryView';
import { IdentifierView } from 'models/IdentifierView';
import { LegalStatusView } from 'models/LegalStatusView';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CountryService, LegalStatusService } from 'services';
import shortid from 'shortid';
import {
  UserHelper,
  generateTempId, isTempId, translate
} from 'utils';
import {
  API_ROUTES, APPLICATION_ROLES, FRENCH_LOCALE, ROUTES, STRUCTURE_TYPE
} from 'utils/constants';

const TAB_MAPPING = [
  'company-rules',
  'institution-rules',
  'legal-status',
  'document'
];

const frenchLocale = 'fr_FR';

const PageCountry = () => {
  const [companySecondaryIdentifierFields, setCompanySecondaryIdentifierFields] = useState([]);
  const [companyPrimaryIdentifierField, setCompanyPrimaryIdentifierField] = useState<IdentifierView>();
  const [institutionSecondaryIdentifierFields, setInstitutionSecondaryIdentifierFields] = useState([]);
  const [institutionPrimaryIdentifierField, setInstitutionPrimaryIdentifierField] = useState<IdentifierView>();
  const history = useHistory();
  const displayModal = useModal();
  const { i18nStore } = useStores();
  const { currentLanguage } = i18nStore;
  const [isLoading, setIsLoading] = useState(true);
  const { countryId, detailType } = useParams();
  const currentTabMapped = TAB_MAPPING.findIndex(url => url === detailType);
  const [currentTab, setCurrentTab] = useState(currentTabMapped > 0 ? currentTabMapped : 0);
  const [currentCountry, setCurrentCountry] = useState<CountryView>();
  const [countryName, setCountryName] = useState(
    (currentCountry?.actualTranslation || (currentCountry?.region)) ? ` - ${currentCountry?.region}` : ''
  );
  const [activated, setActivated] = useState(false);
  const [isLegalStatusLoading, setIsLegalStatusLoading] = useState(false);
  const [legalStatuses, setLegalStatuses] = useState<LegalStatusView[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleTabChange = useCallback((_, tab) => setCurrentTab(tab), []);

  const TABS_COMPONENTS = [
    <CountryRules
      isLoading={isLoading}
      key="company"
      levelType={STRUCTURE_TYPE.COMPANY}
      pageTitle="pageCountry.companyRule.title"
      primaryIdentifierField={companyPrimaryIdentifierField}
      secondaryIdentifierFields={companySecondaryIdentifierFields}
      setPrimaryIdentifierField={setCompanyPrimaryIdentifierField}
      setSecondaryIdentifierFields={setCompanySecondaryIdentifierFields}
    />,
    <CountryRules
      isLoading={isLoading}
      key="institution_model"
      levelType={STRUCTURE_TYPE.INSTITUTION_MODEL}
      pageTitle="pageCountry.institutionRule.title"
      primaryIdentifierField={institutionPrimaryIdentifierField}
      secondaryIdentifierFields={institutionSecondaryIdentifierFields}
      setPrimaryIdentifierField={setInstitutionPrimaryIdentifierField}
      setSecondaryIdentifierFields={setInstitutionSecondaryIdentifierFields}
    />,
    <LegalStatusList
      isLoading={isLegalStatusLoading}
      key="legal_status"
      legalStatuses={legalStatuses}
      setLegalStatuses={setLegalStatuses}
    />
    /* ,
    <PDFDocument
      documentContent={documentContent}
      setDocumentContent={setDocumentContent}
    />
    */
  ];

  const reloadCountry = useCallback(() => {
    setIsLoading(true);
    CountryService.getIdentifiersByCountryId(countryId)
      .then(resp => {
        setCompanySecondaryIdentifierFields(
          resp.filter(identifierField => identifierField.type === STRUCTURE_TYPE.COMPANY && !identifierField.primary)
        );
        setCompanyPrimaryIdentifierField(
          resp.find(identifierField => identifierField.type === STRUCTURE_TYPE.COMPANY && identifierField.primary)
          ?? {
            rule: { format: '' }, idField: generateTempId(), type: STRUCTURE_TYPE.COMPANY, required: true, primary: true
          }
        );
        setInstitutionSecondaryIdentifierFields(
          resp.filter(identifierField => identifierField.type === STRUCTURE_TYPE.INSTITUTION_MODEL && !identifierField.primary)
        );
        setInstitutionPrimaryIdentifierField(
          resp.find(identifierField => identifierField.type === STRUCTURE_TYPE.INSTITUTION_MODEL && identifierField.primary)
          ?? {
            rule: { format: '' }, idField: generateTempId(), type: STRUCTURE_TYPE.INSTITUTION_MODEL, required: true, primary: true
          }
        );
      });
    CountryService.getCountryById(countryId)
      .then(resp => {
        setCurrentCountry(resp);
        setActivated(resp.activated);
      })
      .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar, countryId]);

  useEffect(() => {
    reloadCountry();
  }, [reloadCountry]);

  useEffect(() => {
    if (!UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN])) {
      history.push(ROUTES.HOME);
    }
  }, [history]);

  useEffect(() => {
    if (currentCountry?.translations) {
      const translation = currentCountry?.translations.filter(trad => trad.code === currentLanguage)[0]?.label;
      const countryRegion = (currentCountry.region) ? ` - ${currentCountry.region}` : '';
      translation
        ? setCountryName(translation + countryRegion)
        : setCountryName(`${currentCountry?.translations.filter(trad => trad.code === frenchLocale)[0]?.label}${countryRegion}`);
    }
  }, [currentCountry, setCountryName, currentLanguage]);

  const loadLegalStatuses = useCallback(() => {
    setIsLegalStatusLoading(true);
    LegalStatusService.getLegalStatusByCountryId(countryId)
      .then(resp => setLegalStatuses(resp.map((ls, index) => ({
          ...ls,
          index
        }))))
      .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }))
      .finally(() => setIsLegalStatusLoading(false));
  }, [enqueueSnackbar, setIsLegalStatusLoading, countryId]);

  useEffect(() => {
    loadLegalStatuses();
  }, [loadLegalStatuses]);

  const handleEdit = useCallback(() => {
    displayModal({
      type: 'CREATE_COUNTRY',
      givenCountry: currentCountry,
      setGivenCountry: setCurrentCountry,
      onConfirm: countryEdited => {
        setIsLoading(true);
        CountryService.save(countryEdited)
          .then(() => enqueueSnackbar(translate('pageCountry.snackbar.saved'), { variant: 'success' }))
          .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }))
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  }, [currentCountry, enqueueSnackbar, displayModal]);

  const areFrRulesDescriptionFilled = useCallback(() => {
    const identifiers = [institutionPrimaryIdentifierField, companyPrimaryIdentifierField,
      ...institutionSecondaryIdentifierFields, ...companySecondaryIdentifierFields];
    return identifiers.every(identifier => !identifier?.rule?.format
      || identifier?.rule?.translations?.some(translation => translation.code === FRENCH_LOCALE && translation.label));
  }, [companyPrimaryIdentifierField, companySecondaryIdentifierFields,
    institutionPrimaryIdentifierField, institutionSecondaryIdentifierFields]);

  const areNamesFilled = useCallback(() => {
    const identifiers = [institutionPrimaryIdentifierField, companyPrimaryIdentifierField,
      ...institutionSecondaryIdentifierFields, ...companySecondaryIdentifierFields];
    return identifiers.every(identifier => identifier?.name);
  }, [companyPrimaryIdentifierField, companySecondaryIdentifierFields,
    institutionPrimaryIdentifierField, institutionSecondaryIdentifierFields]);

  const handleActivationChange = useCallback((_, value) => {
    if (areFrRulesDescriptionFilled() && areNamesFilled()) {
      setActivated(value);
    } else {
      setActivated(false);
      !areNamesFilled()
        ? enqueueSnackbar(translate('pageCountry.identifierField.nameRequired'), { variant: 'error' })
        : enqueueSnackbar(translate('pageCountry.rule.missingFrenchDesc.info'), { variant: 'error' });
    }
  }, [areFrRulesDescriptionFilled, areNamesFilled, enqueueSnackbar]);

  const parseIdentifierField = useCallback(identifierField => {
    if (isTempId(identifierField.idField)) {
      return {
        ...identifierField,
        idField: null,
        country: { key: countryId, countryCode: currentCountry.key },
        region: currentCountry.region
      };
    }
    return { ...identifierField };
  }, [countryId, currentCountry]);

  const save = useCallback((editedCountry, editedIdentifierFields) => {
    setIsLoading(true);
    CountryService.save(editedCountry)
      .then(() => {
        Promise.all([
          CountryService.saveIdentifiers(editedIdentifierFields),
          LegalStatusService.save(legalStatuses)
        ]).then(() => {
          enqueueSnackbar(translate('pageCountry.snackbar.saved'), { variant: 'success' });
          reloadCountry();
          loadLegalStatuses();
        }).catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }));
      }).catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar, legalStatuses, reloadCountry, loadLegalStatuses]);

  const handleSave = useCallback(() => {
    if (activated && !areFrRulesDescriptionFilled()) {
      setActivated(false);
      enqueueSnackbar(translate('pageCountry.rule.missingFrenchDesc.countryDisabled'), { variant: 'error' });
      return;
    }
    if (activated && !areNamesFilled()) {
      setActivated(false);
      enqueueSnackbar(translate('pageCountry.rule.identifierField.nameRequired'), { variant: 'error' });
      return;
    }
    const editedCountry = {
      ...currentCountry,
      activated
    };
    const editedIdentifierFields = [
      companyPrimaryIdentifierField,
      institutionPrimaryIdentifierField,
      ...companySecondaryIdentifierFields,
      ...institutionSecondaryIdentifierFields].map(identifier => parseIdentifierField(identifier));
    if (editedIdentifierFields.some(identifierField => !identifierField.name)) {
      displayModal({
        type: 'WARNING',
        title: translate('warnings.warning'),
        text: translate('warnings.identifierField.save.missingNames'),
        buttonConfirm: translate('button.confirm'),
        buttonCancel: translate('button.cancel'),
        onConfirm: () => {
          save(editedCountry, editedIdentifierFields.filter(identifierField => identifierField.name));
        },
        onCancel: () => {
          setIsLoading(false);
        }
      });
    } else {
      save(editedCountry, editedIdentifierFields);
    }
  }, [activated, areFrRulesDescriptionFilled, areNamesFilled, currentCountry, companyPrimaryIdentifierField,
    institutionPrimaryIdentifierField, companySecondaryIdentifierFields, institutionSecondaryIdentifierFields, enqueueSnackbar,
    parseIdentifierField, displayModal, save]);

  return (
    <>
      <HeaderEditionNav fullWidth>
        <Grid alignItems="center" container justifyContent="flex-end">
          <Grid item>
            <Grid alignItems="center" container>
              <ActivationSwitch checked={activated} onChange={handleActivationChange} />
              <Grid item style={{ minWidth: '100px', textAlign: 'left' }}>
                {(activated) ? translate('common.activated') : translate('common.disabled')}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: '30px' }}>
            <Button
              color="primary"
              startIcon={<FontAwesomeIcon icon={faSave} size="2x" />}
              variant="contained"
              onClick={() => handleSave()}
            >
              {translate('button.save')}
            </Button>
          </Grid>
        </Grid>
      </HeaderEditionNav>
      <Wrapper>
        {(isLoading || !currentCountry?.key)
          ? (
            <Grid alignItems="center" container justifyContent="center">
              <Grid item>
                <FontAwesomeIcon icon={faSpinner} spin />
                {translate('common.loading')}
              </Grid>
            </Grid>
          )
          : (
            <Grid alignItems="center" container direction="column" style={{ marginBottom: '30px' }}>
              <Grid item>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item>
                    <Typography align="center" variant="h2">{countryName}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" edge="end" style={{ paddingBottom: 20 }} onClick={handleEdit}>
                      <FontAwesomeIcon color="var(--tecnea-blue)" icon={faEdit} size="sm" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Flag
                  alt={translate('pageCountry.flag') + currentCountry.key}
                  countryCode={currentCountry.key}
                  isInsideSelect={false}
                  style={{ marginTop: '-10px' }}
                  width={80}
                />
              </Grid>
            </Grid>
          )}

        <Tabs
          aria-label={translate('pageCountryDetail.title')}
          centered
          data-cy="countryDetailTabs"
          data-tour="step-admin-country"
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          {UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN]) && [
            <Tab
              component={Link}
              data-cy="editRulesCompany"
              data-tour="step-admin-category"
              icon={<FontAwesomeIcon icon={faList} size="2x" />}
              key={shortid.generate()}
              label={translate('pageCountry.tab.companyRules')}
              to={API_ROUTES.ADMIN_COUNTRIES_DETAIL_COMPANY_RULES(countryId)}
            />,
            <Tab
              component={Link}
              data-cy="editRulesInstitution"
              data-tour="step-admin-category"
              icon={<FontAwesomeIcon icon={faList} size="2x" />}
              key={shortid.generate()}
              label={translate('pageCountry.tab.institutionRules')}
              to={API_ROUTES.ADMIN_COUNTRIES_DETAIL_INSTITUTION_RULES(countryId)}
            />,
            <Tab
              component={Link}
              data-cy="editlegalStatus"
              data-tour="step-admin-category"
              icon={<FontAwesomeIcon icon={faBalanceScale} size="2x" />}
              key={shortid.generate()}
              label={translate('pageCountry.tab.legalStatus')}
              to={API_ROUTES.ADMIN_COUNTRIES_DETAIL_LEGAL_STATUS(countryId)}
            />
            /* ,
            <Tab
              component={Link}
              data-cy="editDocument"
              data-tour="step-admin-category"
              icon={<FontAwesomeIcon icon={faFile} size="2x" />}
              key={shortid.generate()}
              label={translate('pageCountry.tab.document')}
              to={API_ROUTES.ADMIN_COUNTRIES_DETAIL_DOCUMENT(countryId)}
            />
            */
          ]}

        </Tabs>
      </Wrapper>
      <section>
        {TABS_COMPONENTS[currentTab]}
      </section>
    </>

  );
};

export default PageCountry;
