import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Flag } from 'components/_commons/Flag';
import React, { useCallback } from 'react';
import { ItemOption, SelectField } from '..';

const SelectItemWithLeftElement = ({
  isClearable = false,
  isFlagElement,
  isLoading,
  label = 'common.selectOption',
  leftElement,
  name,
  onChange,
  options,
  placeholder,
  required = false,
  value = null,
  valueLabelVariant
}: {
  isClearable?: boolean,
  isFlagElement?: boolean,
  isLoading?: boolean,
  label?: string,
  leftElement?,
  name: string,
  onChange: (value) => void,
  options,
  placeholder?: string,
  required?: boolean,
  value,
  valueLabelVariant?: Variant | 'inherit' | 'srOnly'
}) => {
  const handleChange = useCallback(language => onChange(language), [onChange]);

  const CustomOption = ({ data, innerProps }) => {
    const optionFlag = <Flag alt={data.countryCode ?? data.value} countryCode={data.countryCode ?? data.value} isInsideSelect style={{ margin: '3px 10px' }} />;
    return (
      <ItemOption {...innerProps} isInsideSelect>
        {isFlagElement ? optionFlag : leftElement && leftElement(data)}
        <Typography>
          {data.label}
        </Typography>
      </ItemOption>
    );
  };

  const CustomValue = ({ data, innerProps }) => {
    const valueFlag = <Flag alt={data.countryCode ?? data.value} countryCode={data.countryCode ?? data.value} isInsideSelect style={{ margin: '5px 0' }} />;
    return (
      <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
        {isFlagElement ? valueFlag : leftElement && leftElement(data)}
        <Typography style={{ margin: '0.8rem', whiteSpace: 'nowrap' }} variant={valueLabelVariant}>
          {data.label}
        </Typography>
      </ItemOption>
    );
  };

  return (
    isLoading ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      <SelectField
        components={{ Option: CustomOption, SingleValue: CustomValue }}
        isClearable={isClearable}
        label={label}
        name={name}
        options={options}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={handleChange}
      />
    )
  );
};

export default SelectItemWithLeftElement;
