import {
  GenericListFilters, GenericTable, SelectItemWithLeftElement, TextError, Wrapper
} from 'components';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import { faFileExport, faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck, faLanguage, faPaperPlane, faPeopleArrows, faSave
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  Slide,
  Snackbar,
  Tooltip, Typography,
  makeStyles
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import { useHistory, useParams } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { I18nService, LanguageService } from 'services';
import { DocumentHelper, translate } from 'utils';
import { API_ROUTES, APP_PROFILE } from 'utils/constants';
import { TranslationHelper } from 'utils/helpers';
import {
  listHeaders
} from './TranslationListComponents';
import { TranslationListFilters } from './TranslationListFilters';

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: 20
  }
}));

const SlideTransition = props => <Slide {...props} direction="up" />;

const initialFiltersValidator = () => [
  { key: 'toDecide', value: 'toDecide', label: translate('pageTranslationList.filter.toDecide') },
  { key: 'toDeploy', value: 'toDeploy', label: translate('pageTranslationList.filter.toDeploy') },
  { key: 'toSendBack', value: 'toSendBack', label: translate('pageTranslationList.filter.toSendBack') }
];

const initialFiltersTranslator = () => [
  { key: 'toTranslate', value: 'toTranslate', label: translate('pageTranslationList.filter.toTranslate') },
  { key: 'toSubmit', value: 'toSubmit', label: translate('pageTranslationList.filter.toSubmit') }
];

const CHUNK_SIZE = 20;

const TranslationList = observer(() => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { application, sourceLocale, targetLocale } = useParams();
  const { i18nStore } = useStores();
  const [applicationListForRole, setApplicationListForRole] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [languageListForRole, setLanguageListForRole] = useState([]);
  const [isFetchingLanguages, setIsFetchingLanguages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [someToValidate, setSomeToValidate] = useState(false);
  const [isDismissing, setIsDismissing] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [enableExport, setEnableExport] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [enableImport, setEnableImport] = useState(false);
  const [comparisonList, setComparisonList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [modifiedLabels, setModifiedLabels] = useState({ inCampaign: {}, outCampaign: {} });
  const [everythingSaved, setEverythingSaved] = useState(true);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableMapping, setTableMapping] = useState([]);
  const [totalComparisons, setTotalComparisons] = useState(0);
  const [search, setSearch] = useState('');
  const displayModal = useModal();
  const classes = useStyles();
  const [formState, { raw }] = useFormState({
    application: null,
    sourceLocale: null,
    targetLocale: null
  });

  const isAFF = APP_PROFILE === 'AFF';

  const isUserValidator = TranslationHelper.isValidatorForAppAndLanguage(application, targetLocale);
  const isUserTranslator = TranslationHelper.isTranslatorForAppAndLanguage(application, targetLocale);

  const getRequestParams = useCallback(() => {
    const applicationValue = formState.values.application.value;
    const sourceLocaleValue = formState.values.sourceLocale.locale;
    const targetLocaleValue = formState.values.targetLocale.locale;
    return `application=${applicationValue}&sourceLocale=${sourceLocaleValue}&targetLocale=${targetLocaleValue}`;
  }, [formState.values.application, formState.values.sourceLocale, formState.values.targetLocale]);

  const getFilters = useCallback(() => {
    const categories = filters.filter(f => f.key === 'categories').map(f => f.value);
    const toTranslate = filters.find(f => f.key === 'toTranslate') !== undefined;
    const toSubmit = filters.find(f => f.key === 'toSubmit') !== undefined;
    const toDecide = filters.find(f => f.key === 'toDecide') !== undefined;
    const toDeploy = filters.find(f => f.key === 'toDeploy') !== undefined;
    const toSendBack = filters.find(f => f.key === 'toSendBack') !== undefined;
    const keySearch = filters.find(f => f.key === 'keySearch')?.value || null;
    const sourceSearch = filters.find(f => f.key === 'sourceSearch')?.value || '';
    const targetSearch = filters.find(f => f.key === 'targetSearch')?.value || '';
    return {
      freeSearch: search, categories, toTranslate, toSubmit, toDecide, toDeploy, toSendBack, keySearch, sourceSearch, targetSearch
    };
  }, [search, filters]);

  useEffect(() => {
    setApplicationListForRole(
      i18nStore.applicationListForRole
        .filter(app => !isAFF || ['DATACOMMONS', 'DATATECNEA', 'DATAFORM'].includes(app.value))
        .map(app => (
          { ...app, label: app.label.toUpperCase() }
        ))
    );
  }, [i18nStore.applicationListForRole, isAFF]);

  useEffect(() => {
    if (applicationListForRole.length > 0) {
      const applicationMatch = applicationListForRole.find(app => app.value === application);
      formState.setField('application', applicationMatch || applicationListForRole[0]);
    }
    if (languageList.length > 0) {
      const sourceLocaleMatch = languageList.find(lng => lng.locale.includes(sourceLocale));
      formState.setField('sourceLocale', sourceLocaleMatch || languageList[0]);
    }
    if (languageListForRole.length > 0) {
      const targetLocaleMatch = languageListForRole.find(lng => lng.locale.includes(targetLocale));
      formState.setField('targetLocale', targetLocaleMatch || languageListForRole[0]);
    }
    // eslint-disable-next-line
  }, [application, sourceLocale, targetLocale, applicationListForRole, languageList, languageListForRole]);

  useEffect(() => {
    setIsFetchingLanguages(true);
    setFilters(isUserValidator ? initialFiltersValidator() : initialFiltersTranslator());
    LanguageService.getLanguageListForApplication(`application=${application}`)
      .then(languages => setLanguageList(languages))
      .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => {
        LanguageService.getLanguageListForRoleForApplication(`application=${application}`)
          .then(lng => setLanguageListForRole(lng))
          .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsFetchingLanguages(false));
      });
  }, [application, enqueueSnackbar, isUserValidator]);

  const injectCurrentTranslations = useCallback(({ toDisplayRows, shouldApply = false } = {}) => {
    toDisplayRows.forEach(row => {
      const modifiedLabel = modifiedLabels.inCampaign[row.targetId] || modifiedLabels.outCampaign[row.targetId];
      if (modifiedLabel !== undefined) {
        // eslint-disable-next-line no-param-reassign
        row.modifiedTargetLabel = modifiedLabel;
        if (row.targetStatus && shouldApply) {
          // eslint-disable-next-line no-param-reassign
          row.suggestedTargetLabel = modifiedLabel;
        } else if (shouldApply) {
          // eslint-disable-next-line no-param-reassign
          row.targetLabel = modifiedLabel;
        }
      }
    });
    return toDisplayRows;
  }, [modifiedLabels]);

  const loadTranslationComparisonList = useCallback(() => {
    if (formState.values.application && formState.values.sourceLocale && formState.values.targetLocale) {
      setIsLoading(true);
      I18nService.getTranslationComparisons(getRequestParams(), getFilters())
        .then(resp => {
          setEnableImport(resp.canSubmit || isUserValidator);
          setEnableSubmit(resp.canSubmit);
          setSomeToValidate(resp.canDismiss || resp.canValidate);
          const comparisonResp = resp.comparisons;
          const tableMapper = comparisonResp.reduce((mapping, item, index) => {
            const chunkIndex = Math.floor(index / CHUNK_SIZE);
            const newMapping = [...mapping];
            !newMapping[chunkIndex] && (newMapping[chunkIndex] = []);
            newMapping[chunkIndex].push(item);
            return newMapping;
          }, []);
          let firstDisplay = tableMapper[0] || [];
          firstDisplay = injectCurrentTranslations({ toDisplayRows: firstDisplay });
          setComparisonList(firstDisplay);
          setCurrentPage(1);
          setMaxPage(tableMapper.length);
          setTotalComparisons(comparisonResp.length);
          setTableMapping(tableMapper);
          setEnableExport(Boolean(tableMapper[0]));
        })
        .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
        .finally(() => {
          setIsLoading(false);
          history.replace(API_ROUTES.ADMIN_TRANSLATION_LIST_DETAIL(
            formState.values.application.value,
            formState.values.sourceLocale.locale,
            formState.values.targetLocale.locale
          ));
        });
    }
    // eslint-disable-next-line
  }, [getRequestParams, getFilters, formState.values.application, application,
    formState.values.sourceLocale, formState.values.targetLocale, history, enqueueSnackbar]);

  const updateComparisonList = useCallback(() => {
    if (currentPage > 0 && comparisonList.length < CHUNK_SIZE * (currentPage + 1) && currentPage < maxPage) {
      // currentPage start at 1 when array start at 0, so -1 needed
      let newComparisons = comparisonList.concat(tableMapping[currentPage]);
      newComparisons = injectCurrentTranslations({ toDisplayRows: newComparisons });
      setComparisonList(newComparisons);
      setCurrentPage(currentPage + 1);
    }
    // eslint-disable-next-line
  }, [currentPage, maxPage, comparisonList, tableMapping]);

  useEffect(() => {
    loadTranslationComparisonList();
  }, [loadTranslationComparisonList]);

  const updateModifiedLabels = useCallback(newModifiedLabels => {
    const modifiedInCampaign = Object.values(newModifiedLabels.inCampaign).length > 0;
    const modifiedOutCampaign = Object.values(newModifiedLabels.outCampaign).length > 0;
    setEnableSave(modifiedInCampaign);
    setEverythingSaved(!(modifiedInCampaign || modifiedOutCampaign));
    setModifiedLabels(newModifiedLabels);
  }, []);

  const saveTranslations = useCallback(({ showSnackbar = false, reloadList = false }) => {
    setIsSaving(true);
    injectCurrentTranslations({ toDisplayRows: comparisonList, shouldApply: true });
    I18nService.saveTranslations(modifiedLabels.inCampaign)
      .then(() => {
        showSnackbar && enqueueSnackbar(translate('pageTranslationList.snackbar.manySaved'), { variant: 'success' });
        reloadList && loadTranslationComparisonList();
        updateModifiedLabels({ inCampaign: {}, outCampaign: { ...modifiedLabels.outCampaign } });
        i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
      })
      .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => setIsSaving(false));
  }, [modifiedLabels, enqueueSnackbar, comparisonList, updateModifiedLabels,
    injectCurrentTranslations, loadTranslationComparisonList, i18nStore]);

  const submitTranslations = async () => {
    saveTranslations({});
    displayModal({
      type: 'WARNING',
      text: translate('pageTranslationList.modal.submit'),
      buttonCancel: translate('button.cancel'),
      buttonConfirm: translate('button.submit'),
      title: translate('warnings.warning'),
      onclose: () => {},
      onConfirm: () => {
        setIsSubmitting(true);
        I18nService.submitTranslations(`application=${application}&locale=${targetLocale}`)
          .then(() => {
            enqueueSnackbar(translate('pageTranslationList.snackbar.submitted'), { variant: 'success' });
            loadTranslationComparisonList();
          })
          .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsSubmitting(false));
      }
    });
  };

  const dismissTranslations = async () => {
    displayModal({
      type: 'WARNING',
      text: translate('pageTranslationList.modal.dismiss'),
      buttonCancel: translate('button.cancel'),
      buttonConfirm: translate('button.sendBackTranslator'),
      title: translate('warnings.warning'),
      onclose: () => {},
      onConfirm: async () => {
        setIsDismissing(true);
        saveTranslations({});
        I18nService.dismissTranslations(`application=${application}&locale=${targetLocale}`)
          .then(() => {
            enqueueSnackbar(translate('pageTranslationList.snackbar.dismissed'), { variant: 'success' });
            loadTranslationComparisonList();
          })
          .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsDismissing(false));
      }
    });
  };

  const validateTranslations = async () => {
    displayModal({
      type: 'WARNING',
      text: translate('pageTranslationList.modal.validate'),
      buttonCancel: translate('button.cancel'),
      buttonConfirm: translate('button.validate'),
      title: translate('warnings.warning'),
      onclose: () => {},
      onConfirm: async () => {
        setIsValidating(true);
        saveTranslations({});
        I18nService.validateTranslations(`application=${application}&locale=${targetLocale}`)
          .then(() => {
            enqueueSnackbar(translate('pageTranslationList.snackbar.validated'), { variant: 'success' });
            loadTranslationComparisonList();
          })
          .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsValidating(false));
      }
    });
  };

  const handleChangeLabel = useCallback(({
    newLabel = '', oldLabel = '', id, inCampaign = true
  } = {}) => {
    const newModifiedLabels = {
      inCampaign: { ...(modifiedLabels.inCampaign) },
      outCampaign: { ...(modifiedLabels.outCampaign) }
    };
    if (newLabel !== oldLabel) {
      inCampaign
        ? (newModifiedLabels.inCampaign[id] = newLabel)
        : (newModifiedLabels.outCampaign[id] = newLabel);
    } else {
      inCampaign
        ? delete newModifiedLabels.inCampaign[id]
        : delete newModifiedLabels.outCampaign[id];
    }
    updateModifiedLabels(newModifiedLabels);
  }, [modifiedLabels, updateModifiedLabels]);

  const runToggleAction = useCallback(serviceFunction => {
    setIsStatusLoading(true);
    serviceFunction({ ...getFilters(), applications: [application], locale: targetLocale }, sourceLocale)
      .then(() => {
        loadTranslationComparisonList();
        setIsStatusLoading(false);
      })
      .catch(error => {
        setIsStatusLoading(false);
        error && enqueueSnackbar(error, { variant: 'error' });
      });
  }, [getFilters, application, sourceLocale, targetLocale, loadTranslationComparisonList, enqueueSnackbar]);

  const fullListHeaders = useMemo(() => listHeaders({
    handleChangeLabel,
    runToggleAction: (isUserValidator && someToValidate) ? runToggleAction : undefined,
    isUserTranslator,
    isUserValidator
  }), [someToValidate, handleChangeLabel, runToggleAction, isUserValidator, isUserTranslator]);

  const handleExport = useCallback(() => {
    if (formState.values.application && formState.values.sourceLocale && formState.values.targetLocale) {
      setIsExporting(true);
      I18nService.exportComparison(getRequestParams(), getFilters())
        .then(response => {
          const downloadLink = document.createElement('a');
          downloadLink.download = response.name;
          downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
          downloadLink.click();
        })
        .catch(() => enqueueSnackbar(translate('errors.exportTranslationComparisonError'), { variant: 'error' }))
        .finally(() => {
          setIsExporting(false);
        });
    }
  }, [formState.values.application, formState.values.sourceLocale, formState.values.targetLocale,
    getRequestParams, getFilters, enqueueSnackbar]);

  const handleImport = useCallback(
() => displayModal({
    type: 'IMPORT',
    closeOnSubmit: true,
    onConfirm: file => {
      setIsImporting(true);
      I18nService.importComparison(file)
        .then(() => {
          loadTranslationComparisonList();
          enqueueSnackbar(translate('confirms.translationsImported'), { variant: 'success' });
          i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
        })
        .catch(error => {
          enqueueSnackbar(error, { variant: 'error' });
        })
        .finally(() => setIsImporting(false));
    }
  }),
  [displayModal, enqueueSnackbar, loadTranslationComparisonList, i18nStore]
);

  const handleLoadMore = useCallback(() => {
    !isLoading && updateComparisonList();
  }, [isLoading, updateComparisonList]);

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <TranslationListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const getFavicon = data => (
    <img
      alt=" "
      src={`/assets/images/favicons/${data.value.toLowerCase()}.ico`}
      style={{ width: '25px', height: '25px' }}
    />
  );

  const TitleActions = (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <SelectItemWithLeftElement
            id="application"
            label=""
            leftElement={getFavicon}
            name="application"
            options={applicationListForRole}
            required
            variant="h5"
            {...raw('application')}
            height="50px"
            noMargin
            width="250px"
          />
        </Grid>
        <Grid item>
          <Tooltip title={translate('pageTranslationList.importTooltip')}>
            <span style={{ marginRight: 10 }}>
              <Button
                color="secondary"
                disabled={!enableImport || isImporting}
                startIcon={(<FontAwesomeIcon icon={isImporting ? faSpinner : faFileImport} spin={isImporting} />)}
                variant="contained"
                onClick={handleImport}
              >
                {translate('button.import')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={enableExport
            ? translate('pageTranslationList.exportTooltip')
            : translate('errors.noDataToExport')}
          >
            <span>
              <Button
                color="secondary"
                disabled={isExporting || !enableExport}
                startIcon={<FontAwesomeIcon icon={isExporting ? faSpinner : faFileExport} spin={isExporting} />}
                variant="contained"
                onClick={handleExport}
              >
                {translate('button.export')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <HeaderEditionNav>
        <Grid container spacing={2}>
          {isUserTranslator && (
          <>
            <Grid item>
              <Button
                color="secondary"
                disabled={isSaving || !enableSave}
                startIcon={<FontAwesomeIcon icon={isSaving ? faSpinner : faSave} spin={isSaving} />}
                variant="contained"
                onClick={() => saveTranslations({ showSnackbar: true, reloadList: true })}
              >
                {translate('button.save')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={isSubmitting || !enableSubmit}
                startIcon={<FontAwesomeIcon icon={isSubmitting ? faSpinner : faPaperPlane} spin={isSubmitting} />}
                variant="contained"
                onClick={submitTranslations}
              >
                {translate('button.submit')}
              </Button>
            </Grid>
          </>
          )}
          {isUserValidator && (
          <>
            <Grid item>
              <Button
                color="secondary"
                disabled={isDismissing || !someToValidate}
                startIcon={<FontAwesomeIcon icon={isDismissing ? faSpinner : faPeopleArrows} spin={isDismissing} />}
                variant="contained"
                onClick={dismissTranslations}
              >
                {translate('button.sendBackTranslator')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={isValidating || !someToValidate}
                startIcon={<FontAwesomeIcon icon={isValidating ? faSpinner : faCheck} spin={isValidating} />}
                variant="contained"
                onClick={validateTranslations}
              >
                {translate('button.deploy')}
              </Button>
            </Grid>
          </>
          )}
        </Grid>
      </HeaderEditionNav>
      <Wrapper>
        <PageTitle title={translate('pageTranslationList.title')} titleRight={TitleActions} />
        <Grid container spacing={2}>
          <Grid item>
            <Typography gutterBottom>{translate('pageTranslationList.column.source')}</Typography>
            <SelectItemWithLeftElement
              isLoading={isFetchingLanguages}
              label="common.selectLanguage"
              name="language"
              options={languageList}
              {...raw('sourceLocale')}
              height="50px"
              width="250px"
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>{translate('pageTranslationList.column.target')}</Typography>
            <SelectItemWithLeftElement
              isLoading={isFetchingLanguages}
              label="common.selectLanguage"
              name="language"
              options={languageListForRole}
              {...raw('targetLocale')}
              height="50px"
              width="250px"
            />
          </Grid>
        </Grid>
        <GenericListFilters
          ComponentFilter={renderFilters}
          dataTour="step-translationList-filter"
          filterKey="translationList"
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltipInfo="pageTranslationList.searchTooltip"
          withDrawer
        />
        {!isLoading && comparisonList.length === 0
          ? (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color="var(--primary-color)" icon={faLanguage} size="3x" />
              <TextError>{translate('errors.noTranslations')}</TextError>
            </Grid>
          ) : (
            <GenericTable
              dataCy="comparisonList"
              fixedSize
              hasMore={currentPage < maxPage}
              headers={fullListHeaders}
              id="comparisonList"
              isBlurLoading={isStatusLoading}
              isLoading={isLoading}
              loadMore={handleLoadMore}
              rows={comparisonList}
              total={totalComparisons}
            />
          )}
      </Wrapper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        className={classes.snackbar}
        open={!everythingSaved}
        TransitionComponent={SlideTransition}
      >
        <MuiAlert elevation={6} severity="warning" variant="filled">
          {translate('pageTranslationList.snackbar.notSaved')}
        </MuiAlert>
      </Snackbar>
    </>
  );
});

export default TranslationList;
