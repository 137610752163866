import { Slide, Snackbar, makeStyles } from '@material-ui/core';
import { Footer, Header, SkeletonMain } from 'components';
import GoogleAnalytics from 'components/_commons/GoogleAnalytics/GoogleAnalytics';
import { ModalProvider, useStores } from 'hooks';
import i18n from 'i18next';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Routes } from 'routes/routes';
import { HealthcheckService } from 'services/HealthcheckService';
import { userStore } from 'stores';
import { KeyCloakUtils, translate } from 'utils';
import { APP_PROFILE, FRENCH_LOCALE } from 'utils/constants';
import { FilterHelper } from 'utils/helpers/FilterHelper';

import ErrorBoundary from './ErrorBoundary';

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: 80
  }
}));

const SlideTransition = props => <Slide {...props} direction="up" />;

const SnackBarComponent = ({ message, status, className }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    className={className}
    message={message}
    open={status}
    TransitionComponent={SlideTransition}
  />
);

const App = observer(() => {
  const { i18nStore, editionStore } = useStores();
  const [isOffline, setIsOffline] = useState(false);
  const [isIntegrityCompromised, setIsIntegrityCompromised] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [translationLoaded, setTranslationsLoaded] = useState(false);
  const classes = useStyles();

  i18n.on('languageChanged', () => setTranslationsLoaded(true));

  const checkHealth = () => (
    HealthcheckService.healthcheck()
      .then(healthCheck => {
        setIsOffline(false);
        setIsIntegrityCompromised(false);

        const serviceNotOk = Object.values(healthCheck).find(status => status !== 'OK');
        serviceNotOk && setIsIntegrityCompromised(true);
      }).catch(() => setIsOffline(true))
  );

  const resetEditionMode = useCallback(() => {
    runInAction(() => {
      editionStore.editionMode = false;
    });
  }, [editionStore]);

  // Set an interval to call the healthCheck service
  useEffect(() => {
    setInterval(() => {
      if (userStore.isConnected) {
        checkHealth();
      }
    }, 1000 * 60);
  }, []);

  // Log the user in
  useEffect(() => {
    resetEditionMode();

    document.title = APP_PROFILE === 'AFF' ? 'CLIMECO - COMMONS' : 'DATATECNEA';

    i18nStore.loadLanguageList();

    setIsLoading(true);
    KeyCloakUtils.init().then(keycloak => {
      userStore.connectUser(keycloak);

      if (keycloak.tokenParsed) {
        checkHealth();
        i18nStore.loadApplicationListForRole();

        i18nStore.checkLanguage(keycloak.tokenParsed.locale || FRENCH_LOCALE);
        i18nStore.loadLanguage(keycloak.tokenParsed.locale || FRENCH_LOCALE);

        FilterHelper.initializeDefaultFilters();
      } else if (localStorage.getItem('i18nextLng')) {
        i18nStore.checkLanguage(localStorage.getItem('i18nextLng'));
        i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
      }
      // eslint-disable-next-line
    }).catch(error => console.error((error && error.error_description) || error))
      .finally(() => {
        setIsLoading(false);

        if (!localStorage.getItem('i18nextLng')) {
          i18nStore.loadLanguage(FRENCH_LOCALE);
        }
      });
  }, [i18nStore, editionStore, resetEditionMode]);

  if (isLoading) {
    return <SkeletonMain />;
  }

  return (
    <ModalProvider>
      <ModalProvider>
        <ErrorBoundary>
          {translationLoaded
          ? (
            <>
              <Header />
              <main>
                <Routes />
              </main>
              {!editionStore.editionMode && <Footer />}
            </>
          )
          : <SkeletonMain />}
          <SnackBarComponent
          className={classes.snackbar}
          message={translate('warnings.offline')}
          status={isOffline}
        />
          <SnackBarComponent
          className={classes.snackbar}
          message={translate('warnings.notOperational')}
          status={isIntegrityCompromised}
        />
          <GoogleAnalytics />
        </ErrorBoundary>
      </ModalProvider>
    </ModalProvider>
  );
});

export default withTranslation()(App);
