import {
  faAddressBook, faIdCard, faMailBulk, faSpinner, faUserEdit, faUserTag
} from '@fortawesome/pro-regular-svg-icons';
import { faLanguage, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Card, CardContent, Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CustomIcon, InfoLine, SkeletonAppUser, Text, TextAddress, TextError, WarningHeader, Wrapper
} from 'components';
import { SkeletonDetailUser } from 'components/_commons';
import { useFetch, useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UserService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { KeyCloakUtils, formatDate, translate } from 'utils';
import {
  APP_NAMES_FRONT, APP_PROFILE, FRENCH_LOCALE, ROUTES
} from 'utils/constants';

import { CustomCardTitle } from 'components/_commons/Card/CustomTitle/CustomCardTitle';
import { uniqBy } from 'lodash/array';
import { observer } from 'mobx-react-lite';
import { joinRender } from 'utils/collectionUtils';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const UserDetailHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    color: var(--white);
    font-size: 2.6rem;
    border-radius: 50%;
    border: 2px solid var(--primary-color-dark);
    background-color: var(--primary-color);
  }

  button {
    margin-left: 2rem;
  }
`;

const useStyles = makeStyles(() => ({
  gridItem: {
    flex: 1,
    minWidth: '250px',
    marginTop: '2rem'
  }
}));

const RolesCard = ({
  title,
  applicationUser,
  app,
  activity,
  administrableRolesByStructureLevel,
  isLoadingAdministrableRoles,
  updateRoles,
  updateLanguages
}) => {
  const classes = useStyles();
  const displayModal = useModal();

  const displayModalUpdateRoles = useCallback(appUser => displayModal({
    type: 'UPDATE_ROLES',
    defaultValues: appUser,
    app,
    activity,
    administrableRolesByStructureLevel,
    onConfirm: roles => updateRoles(roles),
    closeOnSubmit: false
  }), [app, activity, displayModal, updateRoles, administrableRolesByStructureLevel]);

  const displayModalUpdateLanguages = useCallback((roleLabel, languages, allowedLanguages) => displayModal({
    type: 'UPDATE_ROLE_LANGUAGES',
    languages: (roleLabel.includes('TRANSLATOR'))
      ? allowedLanguages.filter(l => l.locale !== FRENCH_LOCALE)
      : allowedLanguages,
    defaultLanguages: languages,
    onConfirm: selectedLanguages => updateLanguages(roleLabel, selectedLanguages)
  }), [displayModal, updateLanguages]);

  const needsLanguage = useCallback((role, languagesByRoles) => {
    const languagesByRoleFound = languagesByRoles?.find(languagesByRole => languagesByRole.role.value === role.value);
    return languagesByRoleFound && !languagesByRoleFound.languages.length;
  }, []);

  return (applicationUser.hasAccount
    && (
      <Card>
        <CustomCardTitle backgroundColor="var(--grey)" title={translate(title)}>
          {applicationUser.canEdit && (
            <Button
              color="primary"
              disabled={isLoadingAdministrableRoles}
              startIcon={(
                <FontAwesomeIcon
                  icon={isLoadingAdministrableRoles ? faSpinner : faUserEdit} spin={isLoadingAdministrableRoles}
                />
              )}
              variant="contained"
              onClick={() => displayModalUpdateRoles(applicationUser)}
            >
              {translate('button.edit')}
            </Button>
          )}
        </CustomCardTitle>
        {applicationUser.mapRoles[app] && applicationUser.mapRoles[app].length > 0 ? (
          <CardContent>
            <Grid container direction="column" spacing={3}>
              <Grid className={classes.gridItem} item>
                <Card>
                  <CustomCardTitle
                    backgroundColor="var(--grey-light)"
                    icon={faUserTag}
                    title={translate('pageUserDetail.myRoles')}
                  />
                  <CardContent>
                    <div>
                      {uniqBy(applicationUser.mapRoles[app], role => role.label).map(role => (
                        needsLanguage(role, applicationUser.languagesByRoles)
                          ? (
                            <Text color="var(--grey)" key={shortid.generate()}>
                              {`${role.label} - ${translate('pageUserDetail.role.needsLanguage')}`}
                            </Text>
                          ) : (
                            <Text key={shortid.generate()}>
                              {role.label}
                            </Text>
                          )
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              {applicationUser.languagesByRoles && applicationUser.languagesByRoles.filter(laguageByRole => laguageByRole.role.value.includes(app)).map(languagesByRole => (
                <Grid className={classes.gridItem} item key={shortid.generate()}>
                  <Card>
                    <CustomCardTitle
                      backgroundColor="var(--grey-light)"
                      icon={faLanguage}
                      title={`${languagesByRole.role.label} - ${translate('menu.languages')}`}
                    >
                      {applicationUser.canEdit && (
                        <Button
                          color="primary"
                          disabled={isLoadingAdministrableRoles}
                          startIcon={<CustomIcon icon={isLoadingAdministrableRoles ? faSpinner : faLanguage} secondIcon={faPen} spin={isLoadingAdministrableRoles} />}
                          variant="contained"
                          onClick={e => {
                            e.stopPropagation();
                            displayModalUpdateLanguages(
                              languagesByRole.role.value,
                              languagesByRole.languages,
                              languagesByRole.allowedLanguages
                            );
                          }}
                        >
                          {translate('button.edit')}
                        </Button>
                      )}
                    </CustomCardTitle>
                    <CardContent>
                      <Grid container direction="column" spacing={2}>
                        {languagesByRole.languages.map(lng => (
                          <Grid item key={shortid.generate()}>
                            <Typography>
                              {translate(`languages.${lng.locale}`)}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        ) : (
          <TextError>{translate('errors.noUserRoles')}</TextError>
        )}
      </Card>
    )
  );
};

const UserDetail = observer(() => {
  const { id: personId } = useParams();
  const classes = useStyles();
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { userStore } = useStores();
  const isCurrentUser = userStore?.scope?.personId === personId;
  const keycloak = KeyCloakUtils.currentKeycloakInstance();

  const isAFF = APP_PROFILE === 'AFF';

  const {
    response: user,
    isLoading: isUserLoading
  } = useFetch(() => UserService.getUser(personId), null, [personId], false);

  const [isLoadingAdministrableRoles, setIsLoadingAdministrableRoles] = useState(true);
  const [administrableRolesByStructureLevelAndApp, setAdministrableRolesByStructureLevelAndApp] = useState(
    new Map(Object.values(APP_NAMES_FRONT).map((appName) => [appName, new Map()]))
  );

  useEffect(() => {
    if (user?.person?.mainCompany?.value) {
      setIsLoadingAdministrableRoles(true);
      UserService.getAccessibleRolesWithStructureLevelByAppAndActivity(user?.person?.mainCompany?.value)
        .then((response) => {
          const finalMap = new Map();
          Object.values(response).forEach(
            (accessibleRoleByApp) => {
              const mapByApplication = new Map();
              Object.values(accessibleRoleByApp.accessibleRoleByStructureLevels).forEach((value) => {
                if (isAFF && accessibleRoleByApp.application === 'DATATECNEA') {
                  mapByApplication.set(value.structureLevelOption, value.roleSelectItems
                      .filter(r => r.value !== 'DATACOMMONS_VALIDATOR' && r.value !== 'DATACOMMONS_DATABILAN_VALIDATOR'));
                } else {
                  mapByApplication.set(value.structureLevelOption, value.roleSelectItems);
                }
              });
              finalMap.set(accessibleRoleByApp.application, mapByApplication);
            }
          );
          setAdministrableRolesByStructureLevelAndApp(finalMap);
        })
        .finally(() => setIsLoadingAdministrableRoles(false));
    }
  }, [user?.person?.mainCompany?.value, isAFF]);

  const runAction = useCallback(action => {
    userStore.updateStart();
    action().then(() => {
      enqueueSnackbar(translate('confirms.userDetail.update'), { variant: 'success' });
      setTimeout(() => userStore.keycloak.login(), 1000);
    }).catch(error => {
      enqueueSnackbar((error && error.message) || error, { variant: 'error' });
      userStore.updateEnd();
    });
  }, [enqueueSnackbar, userStore]);

  const updateRoles = useCallback((updateAction, roles) => {
    runAction(() => updateAction(user.accountId, roles));
  }, [user, runAction]);

  const updateLanguages = useCallback((roleLabel, languages) => {
    runAction(() => UserService.updateRoleLanguages(user.accountId, roleLabel, languages));
  }, [user, runAction]);

  const updateUser = useCallback(userUpdated => {
    runAction(() => UserService.updateUser(personId, userUpdated));
  }, [personId, runAction]);

  const displayModalUpdateUser = useCallback(() => displayModal({
    type: 'UPDATE_USER', defaultValues: user, onConfirm: updateUser, closeOnSubmit: false
  }), [displayModal, updateUser, user]);

  const resetPassword = useCallback(() => UserService.resetPassword()
      .then(() => {
        CookiesHelper.setChangePasswordCookie('true');
        keycloak.logout();
      })
      .catch((err) => {
        enqueueSnackbar((err && err.message) || err, { variant: 'error' });
      }), [enqueueSnackbar, keycloak]);

  const displayModalUpdatePassword = useCallback(
      () => displayModal({
        type: 'WARNING',
        title: translate('userDetails.action.updatePassword'),
        text: translate('userDetails.confirmation.updatePassword'),
        onConfirm: () => resetPassword(),
        buttonCancel: translate('button.cancel'),
        buttonConfirm: translate('button.confirm'),
        confirmOnly: true
      }),
      [resetPassword, displayModal]
  );

  if (isUserLoading) {
    return <SkeletonDetailUser/>;
  }

  if (!isUserLoading && !user) {
    return (
      <Grid alignContent="center" container direction="column" justifyContent="center">
        <TextError>{translate('errors.noUserDetail')}</TextError>
        <Link to={ROUTES.USER_LIST}>
          <Button color="primary" variant="contained">
            {translate('button.backToAdminPage')}
          </Button>
        </Link>
      </Grid>
    );
  }

  const firstName = user.person.firstName?.trim();
  const lastName = user.person.lastName?.trim();
  const userFullName = `${firstName} ${lastName}`;

  const hasAnyRole = Object.values(user?.mapRoles)?.some(roles => Boolean(roles?.length));

  return (
    <>
      {Boolean(user?.hasAccount) && !hasAnyRole && (
        <Grid
          item
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 'var(--zindexMedium)'
          }}
        >
          <WarningHeader>
            <Typography color="var(--warning-color)">
              {translate('pageUserDetails.warning.selectRoles')}
            </Typography>
          </WarningHeader>
        </Grid>
      )}
      <Wrapper>
        <UserDetailHeader>
          <span>{`${firstName.toUpperCase().charAt(0)}${lastName.toUpperCase().charAt(0)}`}</span>
          <Typography component="h1" data-cy="userDetailTitle" variant="h1">
            {userFullName}
          </Typography>
        </UserDetailHeader>

        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Card>
              <CustomCardTitle backgroundColor="var(--grey)" title={translate('pageUserDetail.general')}>
                {isCurrentUser && (
                <Button
                        color="secondary"
                        style={{ marginRight: 5 }}
                        variant="contained"
                        onClick={displayModalUpdatePassword}
                    >
                  {translate('button.userDetails.updatePassword')}
                </Button>
                )}
                <Button
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faUserEdit}/>}
                    variant="contained"
                    onClick={displayModalUpdateUser}
                >
                  {translate('button.edit')}
                </Button>
              </CustomCardTitle>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid className={classes.gridItem} item>
                    <Card>
                      <CustomCardTitle
                        backgroundColor="var(--grey-light)"
                        icon={faIdCard}
                        title={translate('pageUserDetail.userInfo')}
                      />
                      <CardContent>
                        <InfoLine
                          info={user.login}
                          label="common.login"
                        />
                        <InfoLine
                          displayIf={user?.person?.contact?.technicalEmail}
                          info={(
                            <a href={`mailto:${user?.person?.contact?.technicalEmail}`}>
                              {user?.person?.contact?.technicalEmail}
                            </a>
                        )}
                          label="common.email"
                          />
                        <InfoLine info={user?.person?.birthDate} label="common.birthDate" />
                        <InfoLine
                          displayIf={user.creationDate}
                          info={formatDate(user.creationDate)}
                          label="common.creationDate"
                        />
                        <InfoLine info={user?.person?.occupation} label="common.occupation" />
                        <InfoLine info={user?.person?.mainCompany?.label} label="common.company" />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid className={classes.gridItem} item>
                    <Card>
                      <CustomCardTitle
                        backgroundColor="var(--grey-light)"
                        icon={faMailBulk}
                        title={translate('common.contact')}
                      />
                      <CardContent>
                        <InfoLine
                            displayIf={user?.person?.contact?.contactEmails}
                            info={user?.person?.contact?.contactEmails && (joinRender(
                                user?.person?.contact?.contactEmails?.split(';'),
                                (mail) => (
                                  <a href={`mailto:${mail}`} key={shortid.generate()}>
                                    <strong>{mail}</strong>
                                  </a>
                                ),
                                    ', '
                                )
                            )}
                            label="common.contactEmails.label"
                        />
                        <InfoLine
                            displayIf={user?.person?.contact?.phoneNumber}
                            info={<a
                                href={`tel:${user?.person?.contact?.phoneNumber}`}>{user?.person?.contact?.phoneNumber}</a>}
                            label="common.phone"
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid className={classes.gridItem} item>
                    <Card>
                      <CustomCardTitle
                        backgroundColor="var(--grey-light)"
                        icon={faAddressBook}
                        title={translate('common.address')}
                      />
                      <CardContent>
                        <InfoLine
                          displayIf={user.person.address && user.person.address.city}
                          info={<TextAddress address={user.person.address} />}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {isUserLoading && (
            <SkeletonAppUser/>
          )}

          {!isUserLoading && user && (
            <>
              {!isAFF && (
                <Grid item>
                  <RolesCard
                    activity=""
                    administrableRolesByStructureLevel={administrableRolesByStructureLevelAndApp.get(APP_NAMES_FRONT.DATABILAN)}
                    app="DATABILAN"
                    applicationUser={user}
                    isLoadingAdministrableRoles={isLoadingAdministrableRoles}
                    title="pageUserDetail.databilan"
                    updateLanguages={updateLanguages}
                    updateRoles={roles => updateRoles(UserService.updateDatabilanRoles, roles)}
                  />
                </Grid>
              )}
              {!isAFF && (
                <Grid item>
                  <RolesCard
                    activity="DATAC2E"
                    administrableRolesByStructureLevel={administrableRolesByStructureLevelAndApp.get(APP_NAMES_FRONT.DATAC2E)}
                    app="DATAC2E"
                    applicationUser={user}
                    isLoadingAdministrableRoles={isLoadingAdministrableRoles}
                    title="pageUserDetail.datac2e"
                    updateLanguages={updateLanguages}
                    updateRoles={roles => updateRoles(UserService.updateDatac2eRoles, roles)}
                  />
                </Grid>
              )}
              <Grid item>
                <RolesCard
                  activity=""
                  administrableRolesByStructureLevel={administrableRolesByStructureLevelAndApp.get(APP_NAMES_FRONT.DATAFORM)}
                  app="DATAFORM"
                  applicationUser={user}
                  isLoadingAdministrableRoles={isLoadingAdministrableRoles}
                  title="pageUserDetail.dataform"
                  updateLanguages={updateLanguages}
                  updateRoles={roles => updateRoles(UserService.updateDataformRoles, roles)}
                />
              </Grid>
              <Grid item>
                <RolesCard
                  activity=""
                  administrableRolesByStructureLevel={administrableRolesByStructureLevelAndApp.get(APP_NAMES_FRONT.DATATECNEA)}
                  app="DATACOMMONS"
                  applicationUser={user}
                  isLoadingAdministrableRoles={isLoadingAdministrableRoles}
                  title="pageUserDetail.datatecnea"
                  updateLanguages={updateLanguages}
                  updateRoles={roles => updateRoles(UserService.updateDatatecneaRoles, roles)}
                />
              </Grid>
              {!isAFF && (
                <Grid item>
                  <RolesCard
                    activity="DATAFLUIDES"
                    administrableRolesByStructureLevel={administrableRolesByStructureLevelAndApp.get(APP_NAMES_FRONT.DATAFLUIDES)}
                    app="DATAFLUIDES"
                    applicationUser={user}
                    isLoadingAdministrableRoles={isLoadingAdministrableRoles}
                    title="pageUserDetail.datafluides"
                    updateLanguages={updateLanguages}
                    updateRoles={roles => updateRoles(UserService.updateDatafluidesRoles, roles)}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Wrapper>
    </>
  );
});

export default UserDetail;
