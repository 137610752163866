import {
  faBars,
  faGlobe,
  faUserCheck,
  faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Fab, Grid, IconButton, Tooltip
} from '@material-ui/core';
import { useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { UserService } from 'services';
import { i18nStore, userStore } from 'stores';
import styled, { css } from 'styled-components';
import { KeyCloakUtils, translate } from 'utils';
import { APP_PROFILE, ROUTES } from 'utils/constants';
import { BrowserDetectionHelper } from 'utils/helpers';
import { HeaderMenu } from './HeaderMenu';
import SideMenu from './SideMenu';

const HeaderContainer = styled.div`
  position: relative;
  height: var(--header-nav-height);
  z-index: var(--zindexBig);
  background: ${APP_PROFILE === 'AFF'
    ? css` var(--header-blue)`
    : css`var(--primary-color) url('/assets/images/bg/header-decoration.png')`};
  background-size: contain;
  background-position: ${props => props.width} center;
  background-repeat: no-repeat;
`;

const CustomGridItem = styled(Grid)`
  position: relative;
  display: flex;
  align-items: center;
  min-width: ${props => props.width};
  height: var(--header-nav-height);
  padding-left: 2rem;
  background: var(--white);

  img {
    max-width: 100%;
    height: 90%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -120px;
    border-left: 60px solid var(--white);
    border-right: 60px solid transparent;
    border-bottom: 60px solid transparent;
  }

  @media (max-width: 820px) {
    display: none;
  }
`;

const Logo = styled(Link)`
  color: var(--white);
  font-size: 2.6rem;

  &:hover {
    text-decoration: none;
    color: var(--white);
  }

  @media (max-width: 820px) {
    margin-left: 2rem;
  }
`;

const ButtonChangeLanguage = styled(IconButton)`
  &:disabled {
    svg {
      display: none;
    }
  }

  span {
    font-size: 1.7rem;
    color: var(--white);
  }
`;

const UserName = styled.span`
  display: inline-block;
  color: var(--white);
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 820px) {
    display: none;
  }
`;

const StyledButtonMenu = styled(Fab).attrs({
  size: 'small'
})`
  position: relative;

  span {
    font-size: 1.4rem;
  }

  .buttonMenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--primary-color);
    background: var(--white);
  }
`;

export const Header = observer(() => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const { isConnected, userConnected } = userStore;
  const { currentLanguage, languageList } = i18nStore;

  const [toggleMenu, setToggleMenu] = useState(false);

  const displayPopupBrowserModal = useCallback(() => showModal({
    type: 'BROWSER_POPUP',
    title: translate('warnings.warning'),
    primaryText: translate('modalBrowser.popupWarningBrowserPrimary'),
    secondaryText: translate('modalBrowser.popupWarningBrowserSecondary')
  }), [showModal]);

  useEffect(() => {
    BrowserDetectionHelper.modalDisplayControl(displayPopupBrowserModal);
  }, [displayPopupBrowserModal]);

  const connectUser = useCallback(() => {
    if (userStore.keycloak) {
      userStore.keycloakLogin().catch(error => enqueueSnackbar((error && error.error_description) || error, { type: 'error' }));
    } else {
      KeyCloakUtils.init().then(keycloak => {
        userStore.connectUser(keycloak);
      }).catch(error => enqueueSnackbar((error && error.error_description) || error, { type: 'error' }));
    }
  }, [enqueueSnackbar]);

  const handleToggleMenu = useCallback(() => setToggleMenu(!toggleMenu), [toggleMenu]);

  const headerItemWidth = isConnected ? 'var(--header-item-width)' : 'var(--header-item-width-guest)';

  const handleChangeLanguage = useCallback(language => {
    if (language) {
      if (userStore.isConnected && language.value !== localStorage.getItem('i18nextLng')) {
        UserService.updateLocale(language.value).then(() => {
          i18nStore.loadLanguage(language.value);
          userStore.keycloak.updateToken(999999)
            .then(refreshed => {
              refreshed && userStore.keycloak.login();
            })
            .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
        });
      } else {
        i18nStore.loadLanguage(language.value);
      }
    }
  }, [enqueueSnackbar]);

  const displayModalLanguage = useCallback(() => showModal({
    type: 'CHANGE_LANGUAGE',
    onConfirm: language => handleChangeLanguage(language),
    defaultValues: currentLanguage
  }), [showModal, handleChangeLanguage, currentLanguage]);

  const isAFF = APP_PROFILE === 'AFF';

  return (
    <>
      <HeaderContainer width={headerItemWidth}>
        <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
          <CustomGridItem item width="var(--header-item-width)">
            {isAFF ? (
              <img
                alt="AFF_GROUP"
                src="/assets/images/companies/AFF_logo.jpg"
              />
            ) : (
              <img alt="TECNEA" src={`${process.env.PUBLIC_URL}/assets/images/companies/tecnea.svg`} />
            )}
          </CustomGridItem>
          <Grid item>
            <Logo to={ROUTES.HOME}>
              {isAFF ? (
                <>
                  <strong>CLIM&apos;</strong>
                  ECO - COMMONS
                  <sup>®</sup>
                </>
              ) : (
                <>
                  <strong>DATA</strong>
                  TECNEA
                  <sup>®</sup>
                </>
              )}
            </Logo>
          </Grid>
          <Grid item>
            <Grid
              alignItems="center"
              container
              justifyContent="flex-end"
              style={{
                minWidth: headerItemWidth, paddingRight: '2rem', height: 'var(--header-nav-height)'
              }}
            >
              {languageList.length > 1 && (
                <Grid item style={{ paddingRight: isMobileOnly ? 0 : '2rem' }}>
                  <Tooltip
                    title={translate('modalChangeLanguage.tooltip', { language: currentLanguage.toUpperCase() })}
                  >
                    <span>
                      <ButtonChangeLanguage onClick={displayModalLanguage}>
                        <FontAwesomeIcon color="#ffffff" icon={faGlobe} size="sm" />
                        <span className="mr1 ml1">{currentLanguage}</span>
                        <FontAwesomeIcon color="#ffffff" icon={faChevronDown} size="xs" />
                      </ButtonChangeLanguage>
                    </span>
                  </Tooltip>
                </Grid>
              )}
              {isConnected ? (
                <>
                  <Media
                    query="(min-width: 820px)"
                    render={() => (
                      <UserName>{userConnected.name}</UserName>
                    )}
                  />
                  <StyledButtonMenu color="primary" data-cy="headerMenu" data-tour="step-header-user" onClick={handleToggleMenu}>
                    <span>{`${userConnected.given_name.charAt(0)}${userConnected.family_name.charAt(0)}`}</span>
                    <span className="buttonMenuIcon">
                      <FontAwesomeIcon icon={faBars} size="xs" />
                    </span>
                  </StyledButtonMenu>
                </>
              ) : !isMobileOnly && (
                <Grid item>
                  <Grid container spacing={2}>
                    {!isAFF && (
                      <Grid item>
                        <Link to={ROUTES.REGISTER}>
                          <Button
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                            variant="contained"
                          >
                            {translate('button.register')}
                          </Button>
                        </Link>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        color="primary"
                        data-cy="connectUser"
                        startIcon={<FontAwesomeIcon icon={faUserCheck} />}
                        variant="contained"
                        onClick={connectUser}
                      >
                        {!isMobileOnly && translate('button.connect')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isConnected && <SideMenu isOpen={toggleMenu} setToggleMenu={setToggleMenu} />}
      </HeaderContainer>
      <HeaderMenu connectUser={connectUser} isConnected={isConnected} />
    </>
  );
});
